@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./_helpers/fonts/Poppins-SemiBold.ttf) format('ttf');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltip {
  @apply invisible absolute;
}
.tooltip {
  display: none
}

.has-tooltip:hover .tooltip {
  @apply visible z-50; 
}
.has-tooltip:hover .tooltip {
  display: initial
}

/* .react-datepicker__input-container input {
  @apply block w-full text-base md:text-sm bg-white border border-gray-300 rounded shadow-sm form-input
  @apply w-full text-sm mx-2 shadow border border-gray-300 rounded p-4 text-gray-500 bg-gray-100 leading-relaxed;
} */

.react-datepicker-wrapper {
  @apply w-full
}

.react-datepicker__tab-loop {
  @apply absolute
}
/* .react-datepicker-popper {
  @apply absolute top-1 z-10 left-0 w-72 text-sm transform-none bg-white shadow px-3 py-2 !important
} */
.react-datepicker__month-container {
  @apply flex flex-col
}
.react-datepicker__month {
  @apply flex flex-col
}
.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800
}
.react-datepicker__week {
  @apply flex justify-around
}
.react-datepicker__day-names {
  @apply flex justify-around text-gray-800 font-medium text-center text-xs
}
.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full
}
.react-datepicker__navigation {
  @apply absolute top-2
}
.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
}
.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
}
.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded hover:bg-blue-200
}
.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-50 hover:bg-transparent
}
.react-datepicker__day--outside-month {
  @apply text-gray-400
}
.react-datepicker__day--in-range {
  @apply bg-gray-200
}
.react-datepicker__day--in-selecting-range {
  @apply bg-blue-200
}
.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-blue-500
}
.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-blue-500
}
.react-datepicker__day--selected {
  @apply bg-blue-500 text-white
}
.react-datepicker__day--range-start {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white
}
.react-datepicker__day--range-end{
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white
}
.react-datepicker__close-icon {
  @apply bg-blue-500 
}

#progressContainer {
  margin-top: 40px;
}

#progressInput {
  margin: 20px auto;
  width: 30%;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: #177125;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 3em;
  font-weight: bold;
  fill:black;
}

.dot-back {
  background-color: #D9DBE9;
}

input:checked ~ .dot-back {
  background-color: #0041AC;
}

input:checked ~ .dot {
  transform: translateX(100%);
  /* background-color: #0041AC; */
}